<template>
  <div class="tags-searcher-field">
    <div class="field">
      <v-autocomplete
        returnObject
        :key="componentKey"
        :value="null"
        :items="items"
        :label="$t('complaints.tags')"
        :search-input.sync="searchValue"
        @input="onChangeValue"
      />
    </div>
    <div class="selected-values">
      <v-chip
        v-for="item in selectedValues"
        close
        color="chipColor"
        close-icon="fa-times"
        :key="item.id"
        :ripple="false"
        :close-label="$t('common.close')"
        @click:close="() => onDeleteItem(item.id)"
      >
        {{ item.text }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import DictionaryService from "@/services/DictionaryService";

export default {
  name: "TagsSearcherField",
  props: {
    selectedValues: { type: Array, default: () => [] },
  },
  data() {
    return {
      componentKey: 0,
      searchValue: null,
      items: [],
    };
  },
  methods: {
    onChangeValue(value) {
      this.searchValue = null;

      if (value) {
        const newValues = [...(this.selectedValues || []), value];
        this.$emit("change", newValues);
      }
    },
    onDeleteItem(itemId) {
      this.componentKey += 1;
      const selectedItems = this.selectedValues.filter(
        (item) => item.id !== itemId
      );
      this.$emit("change", selectedItems);
    },
  },
  watch: {
    searchValue(newValue) {
      if (newValue && newValue.trim()) {
        DictionaryService.GetDictionaryTagsSearch(newValue).then((response) => {
          const selectedItemIds = (this.selectedValues || []).map(
            (item) => item.id
          );

          this.items = response.filter(
            (item) => !selectedItemIds.includes(item.id)
          );
        });
      } else {
        this.items = [];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tags-searcher-field {
  display: flex;
  flex-direction: column;

  .selected-values {
    .v-chip {
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }
}
</style>
